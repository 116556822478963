// Libs.
import React from 'react';
import { graphql } from 'gatsby'
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import { formatFieldsValue } from '../libs/formatFromGraph';
import SustainabilityContainer from '../components/sustainability/sustainability-container';

const SustainabilityPage = ({ data }) => {
  const pageData = formatFieldsValue(data);

  return (
    <Layout>
      <SEO title="Sustainability" />
      <SustainabilityContainer  data={pageData}/>
    </Layout>
  );
};

export default SustainabilityPage;

export const query = graphql`
  query MyQuery {
    hero: configPagesSustainability {
      body: field_sustainability_hero_descri {
        value
      }
      title: field_sustainability_hero_title {
        value
      }
      relationships {
        image: field_sustainability_hero_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1170) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    stewardship: configPagesSustainability {
      text: field_stewardship_description {
        value
      }
      title: field_stewardship_title {
        value
      }
      relationships {
        image: field_stewardship_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1170) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      link: field_stewardship_link {
        title
        uri
      }
    }
    sourcing: configPagesSustainability {
      text: field_sourcing_description {
        value
      }
      title: field_sourcing_title {
        value
      }
      relationships {
        image: field_sourcing_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 555) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      link: field_sourcing_link {
        title
        uri
      }
    }
    healthy: configPagesSustainability {
      text: field_healthy_description {
        value
      }
      title: field_healthy_title {
        value
      }
      relationships {
        image: field_healthy_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 555) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      link: field_healthy_link {
        title
        uri
      }
    }
  }
`;

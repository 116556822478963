import React from 'react';
import { get, cloneDeep } from 'lodash';
import styles from './sustainability-container.module.scss';
import ImageHero from '../common/image-hero/image-hero.js';
import FeaturedImageWithWhiteBox from '../common/image-white-box/image-white-box.js';

import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import GatewayComponent from '../common/gateway-component/gateway-component.js';

const SustainabilityContainer = ({ data }) => {
  return (
    <div className={styles.sustainabilityContainerMain}>
      <div className={styles.sustainabilityContainerHero}>
        <ImageHero data={data.hero} />
      </div>
      <div className={styles.sustainabilityContainerBody}>
        <FeaturedImageWithWhiteBox data={data.stewardship} />

        <div className={styles.sustainabilityContainerCards}>
          <GatewayComponent data={data.sourcing} />
          <GatewayComponent data={data.healthy} />
        </div>
      </div>
    </div>
  );
};

export default SustainabilityContainer;
